import React from 'react';

import Layout from '../components/Layout';

//const IndexPage = () => (
//  <Layout fullMenu>
const IndexPage = () => <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Resume</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <section>
            <p>Education</p>
            <header>
              <h5>Bachelor of Engineering, Mechatronics Engineering and Management (CO-OP)</h5>
              <p>McMaster University, Hamilton, ON | Expected Graduation April 2023</p>
            </header>
            <ul>
              <li>Awarded the PCL Scholarship in Engineering and Management for notable academic standing and significant contribution to university life through extra-curricular activities</li>
              <li>The only female first year engineering student in 2017 and only female Engineering and Management student in 2018-2020 to receive the Marauder Scholar Award for academic achievement while participating on a varsity team</li>
            </ul>
            <header>
              <h5>Exchange Semester, KTH Royal Institute of Technology</h5>
              <p>Stockholm, Sweden | January 2022 - June 2022</p>
            </header>
            <ul>
              <li>SD2905 Human Spaceflight</li>
              <li>DH2650 Computer Game Design</li>
              <li>DH2670 Haptics, Tactile and Tangible Interaction</li>
              <li>AK1213 Swedish Society, Culture and Industry in Historical Perspective</li>
            </ul>
            <hr />
          </section>
          <section>
            <p>Work Experience</p>
            <header>
              <h5>Incoming Software Engineering Intern</h5>
              <p>Meta Inc., Menlo Park, CA | June 2022 - September 2022</p>
            </header>
            <header>
              <h5>Proposal Specialist Co-op</h5>
              <p>Rockwell Automation, Cambridge, ON | May 2021 - August 2021</p>
            </header>
            <header>
              <h5>Network Engineering Coop Student</h5>
              <p>Bell Canada, Mississauga, ON | May 2020 - April 2021</p>
            </header>
            <ul>
              <li>Created a timely and interactive dashboard used for network and KPI analysis that resulted in Bell being named 2020’s fastest 
              mobile network for the first time in 3 years</li>
              <li>Used Python for data analytics and gained experience with MariaDB and Microsoft SQL Server Management Studio</li>
              <li>Built automated scripts for data ingestion and notification systems for abnormalities detected using machine learning</li>
            </ul>
            <header>
              <h5>Tutor</h5>
              <p>Self Employed, McMaster Peer Tutoring & TutorBright | April 2018 - Present</p>
            </header>
            <ul>
              <li>Gave in person and online lessons on a weekly and on demand basis with university students to improve their GPAs in engineering and math courses</li>
              <li>Developed lesson plans for elementary and high shcool English and math courses to challenge advanced students</li>
            </ul>
            <header>
              <h5>Codemakers Instructor</h5>
              <p>Venture Engineering and Science, Hamilton, ON | May 2019 - August 2019</p>
            </header>
            <ul>
              <li>Assisted with project development for programming activities targeted for grades 7-9 that I taught to over 200 campers</li>
              <li>Visited schools throughout southern Ontario to deliver workshops to enhance elementary school students STEM curriculum</li>
              <li>Increased ability to problem solve efficiently during high stress situations while managing classes of over 20 campers</li>
            </ul>
            <header>
              <h5>Fabricator</h5>
              <p>MakerLabs, Vancouver, BC | August 2018</p>
            </header>
            <ul>
              <li>Took detailed instructions then operated laser cutters, machining tools, woodworking tools, and metalworking tools to complete client projects</li>
              <li>Responsible for calibration of machines throughout MakerLabs at the beginning of the day to ensure smooth operations</li>
              <li>Assisted other Fabricators in building one of a kind projects including a DJ booth, music festival entranceway, and snake skin baseball jerseys</li>
            </ul>
            <header>
              <h5>Web Developer</h5>
              <p>Trinity Chapel Multicultural Society, Port Coquitlam, BC | July 2017 - August 2017</p>
            </header>
            <ul>
              <li>Independently created a website using WordPress in a 2 month deadline while reporting website design progress periodically</li>
              <li>Created a simplified user manual to help future unfamiliar web designers navigate WordPress that is still currently being used</li>
              <li>Helped plan an annual family fair by designing advertisements and dealing with vendor logistics</li>
            </ul>
            <hr />
          </section>
          <section>
            <p>Extracurriculars</p>
            <header>
              <h5>McMaster Varsity Water Polo</h5>
              <p>September 2017 - Present</p>
            </header>
            <ul>
              <li>Assisted with project development for programming activities targeted for grades 7-9 that I taught to over 200 campers</li>
              <li>Visited schools throughout southern Ontario to deliver workshops to enhance elementary school students STEM curriculum</li>
              <li>Increased ability to problem solve efficiently during high stress situations while managing classes of over 20 campers</li>
            </ul>
            <header>
              <h5>Digital Ambassador</h5>
              <p>McMaster Engineering Outreach | September 2020 - Present</p>
            </header>
            <ul>
              <li>Connect with students who are interested in McMaster Engineering and first years interested in Mechatronics</li>
              <li>Volunteered at several virtual events as a Mechatronics Representative to share my expertise and experience at McMaster</li>
            </ul>
            <header>
              <h5>VP Academics</h5>
              <p>McMaster Mechatronics Society | April 2019 - April 2020</p>
            </header>
            <ul>
              <li>Took detailed instructions then operated laser cutters, machining tools, woodworking tools, and metalworking tools to complete client projects</li>
              <li>Responsible for calibration of machines throughout MakerLabs at the beginning of the day to ensure smooth operations</li>
              <li>Assisted other Fabricators in building one of a kind projects including a DJ booth, music festival entranceway, and snake skin baseball jerseys</li>
            </ul>
            <header>
              <h5>Workshop Instructor</h5>
              <p>McMaster Makers | September 2019 - April 2020</p>
            </header>
            <ul>
              <li>Deliver monthly projects that encourage students to expand their classroom knowledge and build their own personal projects</li>
              <li>Led workshops of 10 university students ensuring safety and completion of projects while providing a fun learning environment</li>
            </ul>
            <header>
              <h5>Year Representative</h5>
              <p>McMaster EngiQueers | January 2018 - April 2020</p>
            </header>
            <ul>
              <li>Increased interpersonal communications and team work skills through event advertising while planning several events such as a fundraising sock sale and free tie-dying event to help raise awareness of the LGBTQ+ community</li>
            </ul>
          </section>
        </div>
      </section>
    </article>
  </Layout>
//);

export default IndexPage;
